<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :tab="tab"
      :save="saveDocument"
      :revert="refreshDocument"
      v-on="$listeners"
      no-history
      :show-delete="userIsMasterAdmin && tab.isPristine"
      :delete-document="deleteDocument"
    >
      <v-skeleton-loader
        type="list-item"
        v-if="!document.id && !documentIsNew"
      />
    </document-toolbar>
    <document-in-tab>
      <v-form
        class="work-queue-infos"
        v-model="form"
        ref="form"
        :disabled="readonly"
      >
        <v-skeleton-loader
          type="image"
          v-if="!document.id && !documentIsNew"
        />
        <v-card
          v-else
          class="card-width mb-1"
        >
          <v-card-title>
            <span>{{$t('t.WorkQueue')}}</span>
            <!-- <span
              class="ml-1"
              v-if="document.name"
            >{{document.name}}</span> -->
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-around">
              <div class="flex-grow-1 pr-4">
                <div class="d-flex flex-direction column">
                  <v-text-field
                    :label="$t('t.Name')"
                    v-model="document.name"
                    class="ml-5"
                    :rules="rules"
                  />
                  <v-text-field
                    :label="$t('t.ImportKey')"
                    v-model="document.importKey"
                    :rules="rules"
                    class="ml-5"
                  />
                </div>
                <div class="d-flex flex-direction column">
                  <v-checkbox
                    hide-details
                    v-model="document.isDefault"
                    :label="$t('t.IsDefault')"
                    class="ml-5"
                  />
                  <v-checkbox
                    hide-details
                    v-model="document.isImportLocked"
                    :label="$t('t.WorkqueueLocked')"
                    class="ml-5"
                  />
                </div>

                <v-card
                  class="mt-10"
                  elevation="1"
                >
                  <v-card-title class="subtitle-2">{{$t('t.Signature')}}</v-card-title>
                  <v-card-text>
                    <content-editor
                      :height="'300px'"
                      :width="'100%'"
                      class="mb-2 mt-2"
                      :content.sync="document.signature"
                    />
                  </v-card-text>
                </v-card>
              </div>
              <div class="properties">
                <v-card>
                  <v-card-text>
                    <work-queue-properties
                      :workQueue.sync="document"
                      :readonly="readonly"
                    />
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </document-in-tab>
  </div>
</template>

<script>

import Search from '@/pages/search/controllers'
import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    ContentEditor: () => import('@/components/content-editor'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    WorkQueueProperties: () => import('./work-queue-properties')
  },
  data () {
    return {
      rules: [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 100 || this.$t('t.MaxLength', { value: 100 })
      ]
    }
  },
  async created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  computed: {
    actionItem () {
      return {
        id: this.id,
        type: this.tab.documentType
      }
    },
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    },
    cacheType () {
      return DocumentEditor.CacheType.WorkQueueDetail
    },
    readonly () {
      return !this.$store.getters.currentUserHasPermission('WorkQueueSettings')
    },
    form: {
      get () {
        return false
      },
      set (v) {
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(v) })
      }
    },
    id () {
      return this.tab.documentId
    }
  },
  methods: {
    async deleteDocument () {
      Search.getActions(this.actionItem)
        .get('delete-workqueue')
        .executeQuick(this.actionItem, () => this.$router.replace('/'))
    },
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        const defaultWorkQueue = await this.$http().get('/core/v6/work-queues/defaults')
        this.document = Object.assign({}, this.document, {
          name: '',
          importKey: '',
          isDefault: false,
          isImportLocked: false,
          assigned: [],
          visible: defaultWorkQueue?.data?.visible || []
        })
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }
        // this.$emit('document-title-change', { tab: this.tab, value: `${this.document.name}` })

        // this.$store.commit('setModule', {
        //   name: this.document?.name,
        //   icon: 'i.WorkQueues'
        // })
      }
      await this.$waitFor(() => this.$refs.form)
        .then(form => setTimeout(() => {
          form.validate()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    },
    workQueueToJSON () {
      return {
        name: this.document.name,
        importKey: this.document.importKey,
        isDefault: this.document.isDefault,
        isImportLocked: this.document.isImportLocked,
        signature: this.document.signature,
        assigned: this.document.assigned,
        visible: this.document.visible
      }
    },
    async save () {
      if (this.documentIsNew) {
        const response = await this.$http().post('/core/v6/work-queues', this.workQueueToJSON())
        const id = response?.data?.id
        this.$nextTick(() => this.$router.replace({
          path: `/work-queues/${id}`
        }))
        return response
      } else {
        return this.$http().put(`/core/v6/work-queues/${this.document.id}`, this.workQueueToJSON())
      }
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.work-queue-infos
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap
  width 100%
  height 100%

.card-width
  width calc(100% - 12px)
  margin 0 auto 12px

.properties
  width 30%
</style>
